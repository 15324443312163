.container{
    margin-top: 50px;
}
.heading{
    font-size: 3em;
}
.form{
    margin:auto;
    width: 80%;
}
.recipe{
    margin-top:20px;
}