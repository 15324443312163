@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

.typewriter {
  font-family: 'Courier New', Courier, monospace;
  overflow: hidden;
  display: inline-block;
  animation: typing 10s steps(100, end) forwards;
  white-space: nowrap; /* Start with nowrap */
}

.wrapText {
  animation: expandWidth 2s forwards; /* Animation to expand the width */
  white-space: normal; /* Allow text wrapping after expansion */
}

@keyframes expandWidth {
  from { width: 100%; }
  to { width: auto; }
}
